interface ContainerProps {
  className?: string; // Make className optional
  children: React.ReactNode;
  [key: string]: any; // Allow any other props to be passed
}

const Container = ({ className, children, ...props }: ContainerProps) => {
  return (
    <div className={`max-w-7xl mx-auto px-4 ${className}`} {...props}>
      {children}
    </div>
  );
};
export default Container;
