import companyLogo from "../assets/logo.png";
import PersonalImg from "../assets/PayEmi/l2.png";
import VehicleImg from "../assets/PayEmi/l3.png";
import PropertyImg from "../assets/PayEmi/l4.png";
import SharesImg from "../assets/PayEmi/l5.png";
import HomeImg from "../assets/PayEmi/l6.png";
import BusinessImg from "../assets/PayEmi/l7.png";
import WeddingImg from "../assets/PayEmi/l8.png";
import DoctorImg from "../assets/PayEmi/l9.png";
import TravelImg from "../assets/PayEmi/l10.png";
import HolidayImg from "../assets/PayEmi/l11.png";
import CAImg from "../assets/PayEmi/l12.png";
import VisaCard from "../assets/PayEmi/visa-card.png";
import MasterCard from "../assets/PayEmi/mastercard.png";
import Rupay from "../assets/PayEmi/rupay-card.png";
import Phonepe from "../assets/PayEmi/phonepay.png";
import Paytm from "../assets/PayEmi/paytm.png";
import Gpay from "../assets/PayEmi/gpay.png";
import BhimUpi from "../assets/PayEmi/bhim.png";
import PayU from "../assets/PayEmi/payu.png";
import Zoho from "../assets/PayEmi/zoho.png";
import IDBI from "../assets/PayEmi/idbi.png";
import "../styles/payEmi.css";
import { useEffect, useState } from "react";
import Container from "../components/Container";
import QRCode from "react-qr-code";
import CustomRadioButton from "../components/CustomRadioButton";

export default function PayEmi() {
  const [showformA, setShowformA] = useState(false);
  const [loanNumberInput, setLoanNumberInput] = useState("");
  const [laonNumberError, setLoanNumberError] = useState(false);
  const [showformC, setShowformC] = useState(false);
  const [showformB, setShowformB] = useState(false);
  const [lacName, setLacName] = useState("");
  const [lacPhone, setLacPhone] = useState("");
  const [lacOTP, setLacOTP] = useState("");
  const [lacAmount, setLacAmount] = useState("");
  const [dataError, setDataError] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [url, setUrl] = useState("");
  const [showChessPayment, setShowChessPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [qrIsVisible, setQrIsVisible] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [selectedCardMethod, setSelectedCardMethod] = useState<string>("");

  const paymentMethods = [
    { label: "Debit Cards", value: "debitCards", imageSrc: null },
    { label: "UPI", value: "upi", imageSrc: null },
    { label: "Net Banking", value: "netbanking", imageSrc: null },
  ];

  const handlePaymentMethodChange = (value: string) => {
    setSelectedMethod(value);
  };

  const debitCardMethods = [
    { label: "VISA", value: "visa", imageSrc: null },
    { label: "Master Card", value: "mastercard", imageSrc: null },
    { label: "Rupay", value: "rupay", imageSrc: null },
  ];

  const handleDebitCardMethodsChange = (value: string) => {
    setSelectedCardMethod(value);
  };

  const handleClick = () => {
    setShowformA(true);
  };

  const handleChangeAccNo = (e) => {
    setLoanNumberInput(e.target.value);
  };

  const handleBlurAccNo = () => {
    if (loanNumberInput !== "000123456789") {
      setLoanNumberError(true);
    } else {
      setLoanNumberError(false);
    }
  };

  const loanAccount = () => {
    if (!laonNumberError) {
      setShowformB(true);
    }
  };

  // Validation Functions
  const validateLacName = () => {
    const regex = /^[A-Za-z\s]+$/;
    if (!regex.test(lacName)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const validateLacPhone = () => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(lacPhone)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const validateLacOTP = () => {
    const otpRegex = /^\d{4}$/;
    if (!otpRegex.test(lacOTP)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const validateLacAmount = () => {
    const amtRegex = /^\d+(\.\d{2})?$/;
    if (!amtRegex.test(lacAmount)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!dataError) {
      setDataSuccess(true);
      setShowformC(true);
    } else {
      setDataSuccess(false);
      setShowformC(true);
    }
  };

  const generatedInvnos = new Set();

  function generateUniqueInvno() {
    let newInvno;
    do {
      newInvno =
        "IOP-TEST-SAJD-" +
        Math.floor(Math.random() * 1e16)
          .toString()
          .padStart(16, "0");
    } while (generatedInvnos.has(newInvno));
    generatedInvnos.add(newInvno);
    return newInvno;
  }

  useEffect(() => {
    handleQrCodeGenerator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    let timer;
    if (qrIsVisible) {
      timer = setTimeout(() => {
        setQrIsVisible(false);
      }, 60000);
    }
    return () => clearTimeout(timer);
  }, [qrIsVisible]);

  const handleQrCodeGenerator = () => {
    if (!url) {
      setErrorMessage("Please enter a URL.");
      return;
    }
    setErrorMessage("");
    setQrIsVisible(true);
  };

  const handleGenerateQR = async (e) => {
    e.preventDefault();
    const paymentDetails = {
      status: "pending",
      mId: "SBKYKewQFJA=RkZGRlJYWFFQV0lOTVhFTUE=",
      invno: generateUniqueInvno(),
      fName: lacName,
      lName: lacName,
      mNo: lacPhone,
      email: emailID,
      currency: "INR",
      amount: 1,
    };

    try {
      const response = await fetch(
        "https://indiaonlinepay.com/api/iopregisterupiintent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            opStatus: "1",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(paymentDetails),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setShowChessPayment(true);
        console.log("Payment successful:", data);
        setUrl(data.intent);
      } else {
        console.error("Payment failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making payment request:", error);
    }
  };

  return (
    <div className="payEmi-wrapper">
      <div className="w-full h-full flex justify-center items-center py-10 md:pr-30 relative">
        <div className="py-8 px-0 md:px-6 w-full md:w-[650px] bg-[#f1f5f9] bg-opacity-90 min-h-[300px] rounded-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col shadow-shadow_2">
          <Container>
            <div className="logo mb-4 flex justify-center">
              <img src={companyLogo} alt="logo" width={200} loading="lazy" />
            </div>
            <p className="text-slate-800 font-bold text-xs text-center">
              You are logged in from <span className="userIP" /> Mumbai,
              Maharashtra, India
            </p>
            <div className="mt-2 w-full">
              <h4 className="text-green-700 text-md mb-6 font-bold capitalize text-center">
                Demo Payment Page
                <p className="text-slate-900 text-xs lg:text-md font-normal capitalize mt-2">
                  Select your loan
                </p>
              </h4>
              <div className="max-w-7xl mx-auto">
                <div className="flex items-center justify-center flex-wrap gap-x-4 gap-y-4 mb-6">
                  <button
                    className="text-center flex flex-col items-center group w-[60px] h-full"
                    onClick={handleClick}
                  >
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img
                        src={PersonalImg}
                        alt="Short Term Personal Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-600px" }}
                      />
                    </div>
                    <h5 className="text-xs font-medium">STPL</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={VehicleImg} alt="Vehicle Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-100px" }}
                      />
                    </div>
                    <h5 className="text-xs font-medium">Vehicle</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img
                        src={PropertyImg}
                        alt="Property Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-200px" }}
                      />
                    </div>
                    <h5 className="text-xs font-medium">Property</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img
                        src={SharesImg}
                        alt="Shares And Security Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-300px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Shares</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={HomeImg} alt="Home Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-400px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Home</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img
                        src={BusinessImg}
                        alt="Business Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-500px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Business</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={WeddingImg} alt="Wedding Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-700px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Wedding</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={DoctorImg} alt="Doctor Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-800px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Doctor</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={TravelImg} alt="Travel Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-900px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Travel</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={HolidayImg} alt="Holiday Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-1000px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Holiday</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-green-800 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1 border-solid border-[1px]">
                      <img src={CAImg} alt="CA Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-1100px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">CA</h5>
                  </button>
                </div>
              </div>
              {/* Personal loan form start */}
              <form className={`my-2 ${showformA ? "" : "hidden"} `}>
                <div>
                  <div
                    className="form_group w-full flex-col items-start md:flex-row flex justify-between gap-y-3 
                  "
                  >
                    <div className="underline-offset-2 underline font-medium h-9 flex items-center">
                      Loan Account No.
                    </div>
                    <div className="flex flex-col w-full md:w-[40%] relative">
                      <input
                        className="w-full p-2 border rounded text-sm"
                        type="text"
                        placeholder="Loan Account No."
                        name="lac"
                        value={loanNumberInput}
                        onChange={(e) => {
                          handleChangeAccNo(e);
                        }}
                        onBlur={handleBlurAccNo}
                      />
                      <div
                        className={`${
                          laonNumberError
                            ? "text-red-500 text-xs mt-1"
                            : "hidden"
                        } `}
                      >
                        Loan Account No. invalid
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-green-700 text-white p-2 rounded w-full md:w-36 hover:bg-green-800 text-center text-sm h-10"
                      onClick={loanAccount}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className={`my-2 ${showformB ? "" : "hidden"} `}>
                  <div className={`${dataError ? "text-red-500" : "hidden"} `}>
                    Enter valid data
                  </div>
                  <div
                    className={`${
                      dataSuccess ? "text-green-700 font-semibold" : "hidden"
                    } `}
                  >
                    Successfully
                  </div>
                  <div className="form_group_grid w-full grid-cols-1 md:grid-cols-3 mt-2">
                    <div className="mb-2">
                      <input
                        className="w-full p-2 rounded text-sm"
                        type="text"
                        placeholder="Name*"
                        name="lacName"
                        value={lacName}
                        onChange={(e) => setLacName(e.target.value)}
                        onBlur={validateLacName}
                        required
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        className="w-full p-2 rounded text-sm"
                        type="text"
                        placeholder="Phone No*"
                        name="lacPhone"
                        value={lacPhone}
                        onChange={(e) => {
                          setLacPhone(e.target.value);
                          validateLacPhone();
                        }}
                        onBlur={() => {
                          validateLacPhone();
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        className="w-full p-2 rounded text-sm"
                        type="text"
                        placeholder="OTP*"
                        name="lacOTP"
                        value={lacOTP}
                        onChange={(e) => setLacOTP(e.target.value)}
                        onBlur={validateLacOTP}
                      />
                    </div>
                    <div>
                      <input
                        className="w-full p-2 rounded text-sm"
                        type="text"
                        placeholder="Email*"
                        name="email"
                        value={emailID}
                        onChange={(e) => setEmailID(e.target.value)}
                        // onBlur={validateLacAmount}
                      />
                      <div
                        className={`${emailID ? "text-red-500" : "hidden"} `}
                      />
                    </div>
                    <div>
                      <input
                        className="w-full p-2 rounded text-sm"
                        type="text"
                        placeholder="Amount"
                        name="payAmount"
                        value={lacAmount}
                        onChange={(e) => setLacAmount(e.target.value)}
                        onBlur={validateLacAmount}
                      />
                      <div
                        className={`${lacAmount ? "text-red-500" : "hidden"} `}
                      />
                    </div>
                    <button
                      className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm"
                      onClick={handleSubmit}
                    >
                      Pay
                    </button>
                  </div>
                  <div className={`my-2 ${showformC ? "" : "hidden"} `}>
                    <div className="w-full flex items-center justify-center flex-col mb-4">
                      <div className="mt-4 underline-offset-2 underline text-base text-green-900 font-semibold">
                        Pay By
                      </div>
                      <div className="flex flex-row grow form_group">
                        <ul className="items-center w-full text-md font-medium text-gray-900 flex text-sm mt-4 gap-4">
                          {/* debit card upi */}
                          {paymentMethods.map((method) => (
                            <li key={method.value}>
                              <CustomRadioButton
                                key={method.value}
                                name="paymentMethod"
                                label={method.label}
                                value={method.value}
                                selectedValue={selectedMethod}
                                onChange={handlePaymentMethodChange}
                                imageSrc={method.imageSrc}
                                className="pr-4"
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {/* radio content start */}
                    <div>
                      {selectedMethod === "debitCards" && (
                        <div className="my-3 flex items-center justify-center flex-col">
                          <div className="text-sm text-green-700 font-semibold mb-4">
                            Select Your Card
                          </div>
                          <div className="flex flex-row grow">
                            <ul className="items-center w-full text-md font-medium text-gray-900 flex mb-4 gap-4">
                              {debitCardMethods.map((cardMethod) => (
                                <li key={cardMethod.value}>
                                  <CustomRadioButton
                                    name="debitMethod"
                                    label={cardMethod.label}
                                    value={cardMethod.value}
                                    selectedValue={selectedCardMethod}
                                    onChange={handleDebitCardMethodsChange}
                                    imageSrc={cardMethod.imageSrc}
                                    className="pr-4"
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* Visa Details */}
                          {selectedCardMethod === "visa" && (
                            <>
                              {/* <h4 className="w-full block">VISA Details</h4> */}
                              <div className="mb-4">
                                <img
                                  src={VisaCard}
                                  alt="Visa Card"
                                  className="w-20"
                                />
                              </div>
                              <div className=" form_group_grid gap-2 grid-cols-3">
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Name on Card"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Card No."
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow-0 text-sm"
                                    type="text"
                                    placeholder="MM / YY"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="CVV"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Amount"
                                  />
                                </div>
                                <div className="">
                                  <button
                                    data-modal-target="pay-modal"
                                    type="button"
                                    className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm h-10"
                                    onClick={() => {
                                      setShowModal(true);
                                    }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              </div>
                            </>
                          )}

                          {/* Mastercard Details */}
                          {selectedCardMethod === "mastercard" && (
                            <>
                              <div className="mb-4">
                                <img
                                  src={MasterCard}
                                  alt="Visa Card"
                                  className="w-20"
                                />
                              </div>
                              <div className=" form_group_grid gap-2 grid-cols-3">
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Name on Card"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Card No."
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow-0 text-sm"
                                    type="text"
                                    placeholder="MM/YY"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="CVV"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Amount:"
                                  />
                                </div>
                                <div className="">
                                  <button
                                    data-modal-target="pay-modal"
                                    type="button"
                                    className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm h-10"
                                    onClick={() => {
                                      setShowModal(true);
                                    }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              </div>
                            </>
                          )}

                          {/* Rupay Details */}
                          {selectedCardMethod === "rupay" && (
                            <>
                              <div className="mb-4">
                                <img
                                  src={Rupay}
                                  alt="Visa Card"
                                  className="w-20"
                                />
                              </div>
                              <div className=" form_group_grid gap-2 grid-cols-3">
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Name on Card"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Card No."
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow-0 text-sm"
                                    type="text"
                                    placeholder="MM/YY"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="CVV"
                                  />
                                </div>
                                <div className="">
                                  <input
                                    className="w-full p-2 rounded grow text-sm"
                                    type="text"
                                    placeholder="Amount:"
                                  />
                                </div>
                                <div className="">
                                  <button
                                    data-modal-target="pay-modal"
                                    type="button"
                                    className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm h-10"
                                    onClick={() => {
                                      setShowModal(true);
                                    }}
                                  >
                                    Pay
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {/* UPI content start */}
                      <div
                        className={`my-2 flex items-center justify-center flex-col ${
                          selectedMethod === "upi" ? "" : "hidden"
                        }`}
                      >
                        <div className="text-sm text-green-700 font-semibold mb-4">
                          Select Your UPI App
                        </div>
                        <div className="mx-auto">
                          <div className="flex items-center justify-center flex-wrap flex-col gap-4 border border-gray-400 rounded-lg p-3 mb-8 UPI_section">
                            <div className="UPI_icons grid grid-cols-3 gap-5">
                              <button
                                className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center disabled-link"
                                disabled
                              >
                                <img
                                  src={BhimUpi}
                                  alt="bhimupi"
                                  loading="lazy"
                                />
                              </button>
                              <button
                                className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center disabled-link"
                                disabled
                              >
                                <img
                                  src={Phonepe}
                                  alt="phonepe"
                                  loading="lazy"
                                />
                              </button>
                              <button
                                className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center disabled-link"
                                disabled
                              >
                                <img src={Paytm} alt="paytm" loading="lazy" />
                              </button>
                              <button
                                className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center disabled-link"
                                disabled
                              >
                                <img src={Gpay} alt="gapy" loading="lazy" />
                              </button>
                              <button
                                className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center disabled-link"
                                disabled
                              >
                                <img src={PayU} alt="payU" loading="lazy" />
                              </button>
                              <button
                                className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center disabled-link"
                                disabled
                              >
                                <img src={Zoho} alt="zoho" loading="lazy" />
                              </button>
                            </div>
                            <div className="UPI_btns">
                              <button
                                onClick={handleGenerateQR}
                                className="btn-dark border rounded-lg border-gray-30 py-2 px-4 text-center mx-auto col-span-2 text-green-800 text-md font-medium"
                              >
                                Generate IDBI QR
                              </button>
                            </div>

                            {showChessPayment && (
                              // <div className="absolute inset-0 flex items-center justify-center flex-col bg-gray-100 shadow-lg shadow-gray-800 rounded-lg z-50 w-[500px] left-auto right-auto p-8 min-h-[370px] h-full top-1/2 -translate-y-2/4">
                              <div className="w-64 flex items-center bg-white p-4 rounded-xl justify-center shadow-lg flex-col">
                                <img src={IDBI} alt="IDBI" loading="lazy" />
                                <form className="flex items-center justify-center flex-col">
                                  {errorMessage && (
                                    <div className="text-red-500 mb-4 text-sm">
                                      {errorMessage}
                                    </div>
                                  )}
                                  {qrIsVisible && (
                                    <div className="mt-4">
                                      <QRCode value={url} size={100} />
                                    </div>
                                  )}
                                  <div className="mt-4 text-green-bg-green-700">
                                    {/* Transaction Status: {chessPaymentMessage} */}
                                  </div>
                                  {/* {message && (
                                <div className="mt-4 text-green-bg-green-700">
                                  You can close this window now.
                                </div>
                              )} */}
                                </form>
                              </div>
                            )}
                          </div>
                        </div>

                        <button
                          data-modal-target="verify-modal"
                          type="button"
                          className="mx-auto col-span-2 text-center px-8 py-2 btnGreen"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Verify &amp; Pay
                        </button>
                      </div>

                      <div
                        className={`my-2 flex items-center justify-center flex-col ${
                          selectedMethod === "netbanking" ? "" : "hidden"
                        }`}
                      >
                        <div className="text-sm text-green-700 font-semibold mb-4">
                          Currently under integration
                        </div>
                      </div>
                      <div className="my-2 hidden">Wallet</div>
                    </div>
                  </div>
                </div>
              </form>
              {/* Personal loan form end */}
            </div>
          </Container>
        </div>
      </div>
      {/* pay popup modal html */}
      <div
        className={`bg-black/50 fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center modal ${
          showModal ? "" : "hidden"
        }`}
      >
        <div className="relative p-4 pt-6 max-w-6xl py-12 px-12 w-[30%] bg-white bg-opacity-90 min-h-[400px] rounded-lg shadow-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col">
          <button
            className="absolute right-2 top-2 cursor-pointer"
            data-modal-hide="pay-modal"
            type="button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
          <div className="logo mb-10">
            <img src={companyLogo} alt="logo" loading="lazy" />
          </div>
          <h4 className="font-bold text-2xl uppercase text-green-800 mb-3">
            Thank you for Payment
          </h4>
        </div>
      </div>
    </div>
  );
}
