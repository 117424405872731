import Container from "../components/Container";

const InnerBanner = ({ img, alt, children }) => {
  return (
    <section className="relative ">
      <img src={img} alt={alt} className="w-full" height={400} />
      <div className="static lg:absolute lg:top-0 lg:left-0 w-full h-full lg:h-full text-center flex items-center justify-center">
        <Container
          className={
            "flex items-center justify-center flex-col innerGlassEffect"
          }
        >
          {children}
        </Container>
      </div>
    </section>
  );
};
export default InnerBanner;
