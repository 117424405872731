import Container from "../components/Container";

const Page = () => {
  return (
    <>
      <section className="py-8">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 py-2">
            <div className="relative flex flex-col items-start">
              <h4 className="text-2xl sm:text-3xl mb-2">
                <span className="text-green-600 font-bold">Contact Us</span>
              </h4>
              <p>
                Need to get in touch with us? <br />
                Contact us by email at &nbsp;
                <a
                  href="mailto:ashapurtiloans@gmail.com"
                  className="lowercase hover:text-green-900 font-medium hover:underline"
                >
                  ashapurtiloans@gmail.com
                </a>{" "}
                , or stop by our office at the location below.
              </p>
              <div className="space-y-4 text-sm mt-6">
                <h5 className="uppercase font-semibold text-green-700 text-md underline underline-offset-2">
                  Registered Address:
                </h5>
                <ul className="space-y-2">
                  <li>
                    202, Ramakrishna Chambers, <br />
                    Khar Linking Road, Khar (West),
                    <br />
                    Mumbai, 400 052.
                    <br />
                    Maharashtra, India.
                  </li>
                  <li>
                    <b>TEL:</b>&nbsp;+91 9769951630
                  </li>
                  <li>
                    <b>EMAIL:</b>&nbsp;
                    <a
                      href="mailto:ashapurtiloans@gmail.com"
                      className="lowercase hover:text-green-700 hover:underline"
                    >
                      ashapurtiloans@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex items-center w-full">
              <iframe
                title="something"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7498832506103!2d72.83186207580614!3d19.074731452011182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91202520dbb%3A0x762b4c2b09e8c99d!2sGOLDEN%20LEGAND%20LEASING%20AND%20FINANCE%20LTD!5e0!3m2!1sen!2sin!4v1704460424169!5m2!1sen!2sin"
                width="600"
                height="300"
                loading="lazy"
                className="shadow-lg rounded-3xl border-1 border-green-700"
              ></iframe>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Page;
