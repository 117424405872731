import Container from "../components/Container";

const NotFound = () => {
  return (
    <section className="py-12">
      <Container className={""}>
        <div className="flex gap-8 items-start justify-center">
          <div className="text-8xl">404</div>
          <div className="space-y-4">
            <h4 className="text-4xl">
              Sorry, this page <br /> isn&apos;t available!
            </h4>
            <a href="/" className={"btn-dark btn inline-block"}>
              Go to Home
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default NotFound;
