export const FooterData1 = [
  {
    id: 1,
    title: "Loans",
    content: `
      <div className="grid xl:grid-cols-4 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
        <ul className="footer_link">
          <li><a href="/gold-loan">Gold Loan</a></li>
          <li><a href="/personal-loan">Personal Loan</a></li>
          <li><a href="/home-loan">Housing Loan</a></li>
          <li><a href="/vehicle-loan">Vehicle Loan</a></li>
          <li><a href="/business-loan">Business Loan</a></li>
          <li><a href="/property-loan">Property Loan</a></li>
          <li><a href="/arts-antique-loan">Arts & Antiques Loan</a></li>
          <li><a href="/shares-security-loan">Shares & Security Loan</a></li>
          <li><a href="/sme-loan">SME Loan</a></li>
          <li><a href="/holiday-loan">Holiday Loan</a></li>
          <li><a href="/wedding-loan">Wedding Loan</a></li>
          <li><a href="/professional-loans/ca-loan">CA Loan</a></li>
          <li><a href="/professional-loans/doctor-loan">Doctor Loan</a></li>
        </ul>
        <ul className="footer_link">
          <h5 className="uppercase">Application Form</h5>
          <li><a href="/">personal loan Form</a></li>
          <li><a href="/">vehical loan Form</a></li>
          <li><a href="/">home loan Form</a></li>
          <li><a href="/">business loan Form</a></li>
          <li><a href="/">professional loan Form</a></li>
        </ul>
        <ul className="footer_link">
          <h5 className="uppercase">CALCULATOR</h5>
          <li><a href="/">personal loan calculator</a></li>
          <li><a href="/">vehical loan calculator</a></li>
          <li><a href="/">home loan calculator</a></li>
          <li><a href="/">business loan calculator</a></li>
          <li><a href="/">professional loan calculator</a></li>
        </ul>
      </div>
      `,
  },

  {
    id: 2,
    title: "Business",
    content: `
      <div className="grid xl:grid-cols-5 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
        <ul className="footer_link">
          <h5 className="uppercase">About Us</h5>
          <li><a href="/">Mission</a></li>
          <li><a href="/">Vision</a></li>
          <li><a href="/">Team</a></li>
          <li><a href="/">Infra</a></li>
        </ul>
        <ul className="footer_link">
          <h5 className="uppercase">FRANCHISE</h5>
          <li><a href="/">Idea</a></li>
          <li><a href="/">Promise</a></li>
          <li><a href="/">Support</a></li>
          <li><a href="/">Solution</a></li>
          <li><a href="/">Process</a></li>
        </ul>
        <ul className="footer_link">
          <h5 className="uppercase">Investor</h5>
          <li><a href="/">Process</a></li>
          <li><a href="/">Infra</a></li>
          <li><a href="/">Legal</a></li>
          <li><a href="/">Support</a></li>
        </ul>
        <ul className="footer_link">
          <h5 className="uppercase">technology</h5>
          <li><a href="/">The Software</a></li>
        </ul>
        <ul className="footer_link">
          <h5 className="uppercase">commercial</h5>
          <li><a href="/"></a></li>
        </ul>
      </div>`,
  },

  {
    id: 3,
    title: "Legal",
    content: `<div className="grid xl:grid-cols-4 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
      <ul className="footer_link">
        <h5 className="uppercase">legal</h5>
        <li>CIN NO - L65990MH1984PLC033818</li>
        <li>GST NO. - 27AAACG1540L1ZQ</li>
        <li>RBI COR - 13.01171</li>
        <li>LEI - 984500B851F5BNA97906</li>
      </ul>
      <ul className="footer_link">
        <h5 className="uppercase">policies</h5>
        <li><a href="/">terms & conditions</a></li>
        <li><a href="/">Privacy Policy</a></li>
        <li><a href="/">Foreclosure & Cancellations</a></li>
        <li><a href="/">Legal Disclaimer</a></li>
      </ul>
      
    </div>`,
  },
  {
    id: 4,
    title: "Reach US",
    content: `<div className="grid xl:grid-cols-4 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
      <ul className="footer_link">
        <h5 className="uppercase">Address</h5>
        <li>
          202, Ramakrishna Chambers, <br/>
          Khar Linking Road, Khar(West), <br/>
          Mumbai, 400 052, <br/>
          Maharashtra, india.
        </li>
        <li>TEL: +91 9985544266</li>
        <li>EMAIL: <a href="mailto:ashapurtiloans@gmail.com" className="lowercase">ashapurtiloans@gmail.com</a></li>
      </ul>
  
      <ul className="footer_link">
        <h5 className="uppercase">Company</h5>
        <li><a href="/">Blog</a></li>
        <li><a href="/contactUs">Contact Us</a></li>
      </ul>
      
     
      <div className="footer_link col-span-full md:col-span-2">
        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d120664.0213527225!2d72.834207!3d19.074697!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90eaecd861d%3A0x15caf06651358033!2sAsha%20Purti%20Loans!5e0!3m2!1sen!2sin!4v1700036086255!5m2!1sen!2sin" width="100%" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="shadow-lg rounded-3xl"></iframe>
        </div>
      </div>
    </div>`,
  },
];
