import { Provider } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { store } from "./store";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

const ReduxProvider = ({ children }) => {
  // const currentPath = window.location.pathname;

  const location = useLocation();
  const pathname = location.pathname; // Get the current path
  const noLayout = ["/PayEmi"];

  return (
    <Provider store={store}>
      {!noLayout.includes(pathname) ? (
        <>
          <AnimatePresence mode="wait">
            <Header />
            {children}
            <Footer />
            <motion.div
              className="slide-in"
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 0 }}
              exit={{ scaleY: 1 }}
              transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            ></motion.div>
            <motion.div
              className="slide-out"
              initial={{ scaleY: 1 }}
              animate={{ scaleY: 0 }}
              exit={{ scaleY: 0 }}
              transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            ></motion.div>
          </AnimatePresence>
        </>
      ) : (
        <>{children}</>
      )}
    </Provider>
  );
};
export default ReduxProvider;
