import React from "react";
import Container from "./Container";
import Navbar from "./Navbar";
import MobileNav from "./MobileNav";
// const logo = require("../assets/logo.png");
import logo from "../assets/logo.png";

function Header() {
  return (
    <header id="header">
      <div className="w-full py-4">
        <Container className={"flex items-center justify-between"}>
          <a href="/" className="logo transition-all duration-500 ease-in-out">
            <img src={logo} alt="Ashapurti logo" />
          </a>
          <a
            href="/payEmi"
            className="btn btn-secondary text-slate-900 lg:!px-7 border-yellow-300  !py-2 shadow-shadow_2 !font-medium tracking-widest text-sm"
          >
            Pay EMI
          </a>
        </Container>
      </div>
      <div className="nav-area">
        <Navbar />
        <MobileNav />
      </div>
    </header>
  );
}

export default Header;
