import React, { useState, useEffect, useRef } from "react";
import { Range, getTrackBackground } from "react-range";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

// Define TypeScript interfaces
interface LoanCalculatorState {
  loanAmount: number;
  interestRate: number;
  loanYears: number;
  loanMonths: number;
}

// Define types for the states
type TenureTab = "years" | "months";

const LoanEMICalculator: React.FC = () => {
  // State to track which tab is selected (years or months)
  const [tenureTab, setTenureTab] = useState<TenureTab>("years");

  // States for loan tenure
  const [loanTenureYears, setLoanTenureYears] = useState<number>(20);
  const [loanTenureMonths, setLoanTenureMonths] = useState<number>(240);

  const [state, setState] = useState<LoanCalculatorState>({
    loanAmount: 5000000, // Default loan amount ₹5,000,000
    interestRate: 9, // Default interest rate 0%
    loanYears: 20, // Default loan tenure 20 years
    loanMonths: 0, // Default loan months set to 0
  });

  const loanEMIValueRef = useRef<HTMLParagraphElement>(null);
  const totalInterestValueRef = useRef<HTMLParagraphElement>(null);
  const totalAmountValueRef = useRef<HTMLParagraphElement>(null);

  // Calculate EMI
  //   const calculateEMI = () => {
  //     // Calculate total tenure in months
  //     const totalMonths = state.loanYears * 12 + state.loanMonths;

  //     const interest = state.interestRate / 12 / 100; // Monthly interest rate
  //     const emi =
  //       state.loanAmount *
  //       interest *
  //       (Math.pow(1 + interest, totalMonths) /
  //         (Math.pow(1 + interest, totalMonths) - 1));
  //     return emi;
  //   };
  const calculateEMI = () => {
    const totalMonths = state.loanYears * 12 + state.loanMonths; // Total months
    const monthlyRate = state.interestRate / 12 / 100; // Monthly interest rate

    if (monthlyRate === 0) {
      // If interest rate is 0, divide loan amount by tenure
      return state.loanAmount / totalMonths;
    }

    // EMI calculation formula
    const emi =
      (state.loanAmount *
        monthlyRate *
        Math.pow(1 + monthlyRate, totalMonths)) /
      (Math.pow(1 + monthlyRate, totalMonths) - 1);

    return emi;
  };

  // Update data on EMI, Interest, and Total Amount
  //   const updateData = (emi: number) => {
  //     if (!isNaN(emi) && isFinite(emi)) {
  //       loanEMIValueRef.current!.innerText = Math.round(emi).toString();
  //       const totalAmount = Math.round(
  //         state.loanAmount + emi * (state.loanYears * 12 + state.loanMonths)
  //       );
  //       totalAmountValueRef.current!.innerText = totalAmount.toString();
  //       const totalInterestPayable = Math.round(totalAmount - state.loanAmount);
  //       totalInterestValueRef.current!.innerText =
  //         totalInterestPayable.toString();
  //     } else {
  //       loanEMIValueRef.current!.innerText = "";
  //       totalAmountValueRef.current!.innerText = "";
  //       totalInterestValueRef.current!.innerText = "";
  //     }
  //   };

  const updateData = (emi: number) => {
    if (!isNaN(emi) && isFinite(emi)) {
      // Format EMI value using 'hi-IN' locale for Indian currency style
      loanEMIValueRef.current!.innerText = `₹${emi.toLocaleString("en-IN")}`;

      // Calculate total payment and format
      const totalPayment = Math.round(
        emi * (state.loanYears * 12 + state.loanMonths)
      );
      totalAmountValueRef.current!.innerText = `₹${totalPayment.toLocaleString(
        "en-IN"
      )}`;

      // Calculate total interest payable and format
      const totalInterestPayable = Math.round(totalPayment - state.loanAmount);
      totalInterestValueRef.current!.innerText = `₹${totalInterestPayable.toLocaleString(
        "en-IN"
      )}`;
    } else {
      loanEMIValueRef.current!.innerText = "";
      totalAmountValueRef.current!.innerText = "";
      totalInterestValueRef.current!.innerText = "";
    }
  };

  // Recalculate on state change
  useEffect(() => {
    const emi = calculateEMI();
    updateData(emi);
  }, [state]);

  // Handle input changes for Loan Amount, Interest Rate, and Loan Tenure
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: parseFloat(value),
    }));
  };

  // Handle range slider changes
  const handleRangeChange = (name: string, value: number) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Data for Pie Chart (Loan vs Interest)
  //   const chartData = [
  //     { name: "Loan Amount", value: state.loanAmount },
  //     {
  //       name: "Total Interest Payable",
  //       value: parseFloat(totalInterestValueRef.current?.innerText || "0"),
  //     },
  //   ];

  const emi = calculateEMI();
  const totalAmount = emi * (state.loanYears * 12 + state.loanMonths);
  const totalInterestPayable = totalAmount - state.loanAmount;
  const chartData = [
    { name: "Principal Amount", value: state.loanAmount },
    { name: "Total Interest Payable", value: totalInterestPayable },
  ];

  // Define chart colors
  const COLORS = ["#46b154", "#fc5b2b", "#FFBB28"];

  return (
    <div className="bg-green-900 rounded-2xl pt-3 w-full">
      <h2 className="text-center text-lg font-semibold text-yellow-500">
        Loan EMI Calculator
      </h2>

      <div className="flex gap-4 mt-2 px-6">
        <div className="mb-6 w-4/12">
          <div className="h-7 mb-4">
            <label className="text-sm font-normal text-white">
              Loan Amount (₹)
            </label>
          </div>

          <input
            type="number"
            name="loanAmount"
            value={state.loanAmount}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
          <Range
            values={[state.loanAmount]}
            step={10000}
            min={50000}
            max={5000000}
            onChange={(values) => handleRangeChange("loanAmount", values[0])}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "6px",
                  width: "95%",
                  background: getTrackBackground({
                    values: [state.loanAmount],
                    colors: ["#dfce0f", "#ccc"],
                    min: 50000,
                    max: 5000000,
                  }),
                  borderRadius: "10px",
                  marginTop: "0.875rem",
                  marginBottom: "0.875rem",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#efd021",
                  border: "2px solid #333",
                }}
              />
            )}
          />
        </div>

        <div className="mb-6 w-3/12">
          <div className="h-7 mb-4">
            <label className="text-sm font-normal text-white">
              Interest Rate (%)
            </label>
          </div>
          <input
            type="number"
            name="interestRate"
            value={state.interestRate}
            onChange={handleInputChange}
            className="border p-2 rounded w-full"
          />
          <Range
            values={[state.interestRate]}
            step={0.1}
            min={0}
            max={20}
            onChange={(values) => handleRangeChange("interestRate", values[0])}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "6px",
                  width: "95%",
                  background: getTrackBackground({
                    values: [state.interestRate],
                    colors: ["#dfce0f", "#cccddd"],
                    min: 0,
                    max: 20,
                  }),
                  borderRadius: "10px",
                  marginTop: "0.875rem",
                  marginBottom: "0.875rem",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#ead336",
                  border: "2px solid #333",
                }}
              />
            )}
          />
        </div>

        {/* Loan Tenure Tabs */}
        <div className="mb-6 w-4/12">
          <div className="flex justify-between mb-4 h-7">
            <label className="text-sm font-normal text-white">
              Loan Tenure ({tenureTab === "years" ? "Years" : "Months"})
            </label>
            <div className="flex items-center justify-center gap-2">
              <button
                className={`rounded border text-sm px-2 w-9 flex items-center justify-center ${
                  tenureTab === "years"
                    ? "bg-yellow-500 text-white hover:bg-yellow-800"
                    : "bg-gray-200 text-black hover:bg-yellow-800 hover:text-white"
                }`}
                onClick={() => {
                  if (tenureTab === "months") {
                    setLoanTenureYears(Math.floor(loanTenureMonths / 12)); // Convert months to years
                  }
                  setTenureTab("years");
                }}
              >
                Yr
              </button>
              <button
                className={`rounded border text-sm px-2 w-9 flex items-center justify-center ${
                  tenureTab === "months"
                    ? "bg-yellow-500 text-white hover:bg-yellow-800"
                    : "bg-gray-200 text-black hover:bg-yellow-800 hover:text-white"
                }`}
                onClick={() => {
                  if (tenureTab === "years") {
                    setLoanTenureMonths(loanTenureYears * 12); // Convert years to months
                  }
                  setTenureTab("months");
                }}
              >
                Mo
              </button>
            </div>
          </div>
          <div>
            <input
              type="number"
              min="0"
              max={tenureTab === "years" ? 30 : 360}
              step="1"
              value={tenureTab === "years" ? loanTenureYears : loanTenureMonths}
              onChange={(e) =>
                tenureTab === "years"
                  ? setLoanTenureYears(Number(e.target.value))
                  : setLoanTenureMonths(Number(e.target.value))
              }
              className="border p-2 rounded w-full"
            />
            <Range
              values={[
                tenureTab === "years" ? loanTenureYears : loanTenureMonths,
              ]}
              step={1}
              min={0}
              max={tenureTab === "years" ? 30 : 360}
              onChange={(values) =>
                tenureTab === "years"
                  ? setLoanTenureYears(values[0])
                  : setLoanTenureMonths(values[0])
              }
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "95%",
                    background: getTrackBackground({
                      values: [
                        tenureTab === "years"
                          ? loanTenureYears
                          : loanTenureMonths,
                      ],
                      colors: ["#dfce0f", "#cccddd"],
                      min: 0,
                      max: tenureTab === "years" ? 30 : 360,
                    }),
                    borderRadius: "10px",
                    marginTop: "0.875rem",
                    marginBottom: "0.875rem",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    backgroundColor: "#ead336",
                    border: "2px solid #333",
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* EMI and Loan Details */}
      <div className="flex items-center justify-between bg-white px-4 py-4 border-green-100 rounded-bl-2xl rounded-br-2xl gap-4">
        <div className="w-3/12">
          <h3 className="text-green-800 font-bold text-lg text-center">
            Loan EMI
          </h3>
          <p
            ref={loanEMIValueRef}
            className="underline underline-offset-4 text-lg text-black font-semibold text-center"
          ></p>
        </div>
        <div className="w-5/12">
          <h3 className="text-green-800 font-bold text-lg text-center">
            Total Interest Payable
          </h3>
          <p
            ref={totalInterestValueRef}
            className="underline underline-offset-4 text-lg text-black font-semibold text-center"
          ></p>
        </div>
        <div className="w-4/12">
          <h3 className="text-green-800 font-bold text-lg text-center">
            Total Amount
          </h3>
          <p
            ref={totalAmountValueRef}
            className="underline underline-offset-4 text-lg text-black font-semibold text-center"
          ></p>
        </div>
      </div>

      {/* Loan Distribution Pie Chart */}
      {/* <div className="">
        <PieChart width={560} height={300}>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label={({ name, percent }) =>
              `${name}: ${(percent * 100).toFixed(0)}%`
            }
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div> */}
    </div>
  );
};

export default LoanEMICalculator;
