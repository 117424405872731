import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useDispatch } from "react-redux";
import { toggleEnquiryModal } from "../redux/globalSlice";
import Container from "./Container";
import HOMEBANNER_1 from "../assets/banner_1.jpg";
// const HOMEBANNER_1 = require("../assets/banner_1.jpg");

const HomeSlider = () => {
  const dispatch = useDispatch();
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null);
  const openEqModal = () => {
    dispatch(toggleEnquiryModal());
  };
  return (
    <section className="relative" id="homeSlider">
      <Swiper
        modules={[Navigation]}
        navigation={{
          prevEl,
          nextEl,
        }}
        spaceBetween={0}
        slidesPerView={1}
        loop
      >
        <SwiperSlide>
          <div className="relative">
            <img src={HOMEBANNER_1} alt="home banner" className="w-full" />
            <div className="bg-black/25 w-full h-full left-0 top-0 absolute"></div>
          </div>
          <div className="static lg:absolute lg:top-0 w-full h-full z-[1] bg-green-600 lg:bg-transparent py-4 lg:py-0">
            <Container className={"relative h-full"}>
              <div className="uppercase text-white static lg:absolute lg:top-1/2 lg:-translate-y-1/2 left-0 right-0 text-center banner-text glassEffect ">
                <h5 className="text-2xl lg:text-4xl xl:text-6xl font-bold text-shadow-text-custom">
                  Service from
                </h5>
                <h4 className="text-3xl lg:text-5xl xl:text-7xl font-bold">
                  the expert
                </h4>
                <p className="text-sm lg:text-md lg:text-xl mt-4 max-w-lg lg:max-w-3xl mx-auto mb-6">
                  Moving forward you will be paired with a highly vetted
                  professional for news about bookkeeper
                </p>
                <div className="btnGroups gap-4 flex justify-center">
                  <a
                    className="btn btn-gold text-slate-900 lg:!py-3 lg:!px-7 border-yellow-300 text-xs md:text-base"
                    href="/"
                  >
                    About Company
                  </a>
                  <a
                    className="btn btn-gold text-slate-900 lg:!py-3 lg:!px-7 border-yellow-300 text-xs md:text-base"
                    href="/"
                  >
                    Get a Quote
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src={HOMEBANNER_1} alt="home banner" />
            <div className="bg-black/25 w-full h-full left-0 top-0 absolute"></div>
          </div>
          <div className="static lg:absolute lg:top-0 w-full h-full z-[1] bg-green-600 lg:bg-transparent py-4 lg:py-0">
            <Container className={"relative h-full"}>
              <div className="uppercase text-white static lg:absolute lg:top-1/2 lg:-translate-y-1/2 left-0 right-0 text-center banner-text glassEffect">
                <h5 className="text-2xl lg:text-4xl xl:text-5xl font-bold">
                  Service from
                </h5>
                <h4 className="text-3xl lg:text-5xl xl:text-6xl font-bold">
                  the expert
                </h4>
                <p className="text-sm lg:text-md lg:text-xl mt-4 max-w-lg lg:max-w-3xl mx-auto">
                  Moving forward you will be paired with a highly vetted
                  professional for news about bookkeeper
                </p>
                <div className="btnGroups">
                  <a
                    className="btn btn-gold text-slate-900 lg:!py-3 lg:!px-7"
                    href="/"
                  >
                    About Company
                  </a>
                  <a
                    className="btn btn-gold text-slate-900 lg:!py-3 lg:!px-7 border-yellow-300"
                    href="/"
                  >
                    Get a Quote
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src={HOMEBANNER_1} alt="home banner" />
            <div className="bg-black/25 w-full h-full left-0 top-0 absolute"></div>
          </div>
          <div className="static lg:absolute lg:top-0 w-full h-full z-[1] bg-green-600 lg:bg-transparent py-4 lg:py-0">
            <Container className={"relative h-full"}>
              <div className="uppercase text-white static lg:absolute lg:top-1/2 lg:-translate-y-1/2 text-center banner-text glassEffect">
                <h5 className="text-2xl lg:text-4xl xl:text-6xl font-bold">
                  Service from
                </h5>
                <h4 className="text-3xl lg:text-5xl xl:text-7xl font-bold">
                  the expert
                </h4>
                <p className="text-sm lg:text-md lg:text-xl mt-4 max-w-lg lg:max-w-3xl mx-auto">
                  Moving forward you will be paired with a highly vetted
                  professional for news about bookkeeper
                </p>
                <div className="btnGroups">
                  <a
                    className="btn btn-gold text-slate-900 lg:!py-3 lg:!px-7"
                    href="/"
                    onClick={openEqModal}
                  >
                    About Company
                  </a>
                  {/* <button
                    className="bg-white/10 border-amber-500 border-2 text-white px-3 py-1.5 lg:py-3 lg:px-7 rounded-lg font-medium"
                    onClick={openEqModal}
                  >
                    Get a Quote
                  </button> */}
                  <a
                    className="btn btn-gold text-slate-900 lg:!py-3 lg:!px-7 border-yellow-300"
                    href="/"
                    onClick={openEqModal}
                  >
                    Get a Quote
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </SwiperSlide>
      </Swiper>
      <button
        ref={(node) => setPrevEl(node)}
        className="absolute top-1/2 -translate-y-1/2 left-2 lg:left-20 z-[2] border-2 rounded-full w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20 flex items-center justify-center scale-75 hover:scale-100 ease-in-out duration-300 "
      >
        <ChevronLeft size={60} strokeWidth="1" color="white" />
      </button>
      <button
        ref={(node) => setNextEl(node)}
        className="absolute top-1/2 -translate-y-1/2 right-2 lg:right-20 z-[2] border-2 rounded-full w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20 flex items-center justify-center scale-75 hover:scale-100 ease-in-out duration-300"
      >
        <ChevronRight size={60} strokeWidth="1" color="white" />
      </button>
    </section>
  );
};
export default HomeSlider;
