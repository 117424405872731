import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import ReduxProvider from "./redux/ReduxProvider";
import Footer from "./components/Footer";
import LoanDetails from "./screens/LoanDetails";
import KnowledgeCenter from "./screens/KnowledgeCenter";
import ContactUs from "./screens/ContactUs";
import Faq from "./screens/Faq";
import PayEmi from "./screens/PayEmi";

function App() {
  return (
    <Router>
      {/* <BrowserRouter> */}
      <ReduxProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/knowledge-center" element={<KnowledgeCenter />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />

          <Route path="/:loanType" element={<LoanDetails />} />
          <Route path="/payEmi" element={<PayEmi />} />
        </Routes>
      </ReduxProvider>
      {/* </BrowserRouter> */}
    </Router>
  );
}

export default App;
