import { FC, ButtonHTMLAttributes, AnchorHTMLAttributes } from "react";

type ButtonAsButton = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: "button";
};

type ButtonAsLink = AnchorHTMLAttributes<HTMLAnchorElement> & {
  as: "Link";
};

type ButtonProps = (ButtonAsButton | ButtonAsLink) & {
  className?: string;
};

const Button: FC<ButtonProps> = ({
  type = "button",
  className = "",
  children,
  as = "button",
  ...props
}) => {
  if (as === "Link") {
    return (
      <a
        {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
        className={`btn ${className}`}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      type={type as ButtonHTMLAttributes<HTMLButtonElement>["type"]}
      className={`btn ${className}`}
      {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};

export default Button;
