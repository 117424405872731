import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import parse from "html-react-parser";
import Container from "./Container";

const FooterAccordionItem = ({ title, content, isOpen, onClick }) => (
  <div className="footer_accordion-item">
    <div className="footer_accordion-header">
      <div
        className={`footer_accordion-title relative${
          isOpen ? "! text-white pb-2" : "!text-white"
        } transition-all ease-in-out duration-500 cursor-pointer `}
        onClick={onClick}
      >
        <span
          className={
            isOpen
              ? "border-b border-green-600 transition-colors duration-500 text-green-600"
              : "text-slate-200"
          }
        >
          {parse(title)}
        </span>
        <div className="absolute top-1 left-auto right-0">
          <IoIosArrowDown
            className={`${
              isOpen
                ? "transform rotate-180  text-white transition-all ease-in-out duration-500 "
                : "text-white transition-all ease-in-out duration-500"
            } ml-5 w-4 h-4`}
          />
        </div>
      </div>
    </div>
    <div
      className={`footer_accordion-content transition-all ease-in-out duration-500 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div>{parse(content)}</div>
    </div>
  </div>
);

const FooterAccordion = ({ data }) => {
  const lastPanelIndex = data.length - 1;

  const [openPanel, setOpenPanel] = useState(lastPanelIndex);

  const handlePanelClick = (index) => {
    setOpenPanel(index === openPanel ? null : index);
  };

  return (
    <>
      <section className="w-full my-5">
        <Container className={""}>
          <div className="footer_accordion md:h-[auto] overflow-y-auto footer_accordion_scroll px-0 md:px-8">
            {data.map((item, index) => (
              <FooterAccordionItem
                key={index}
                title={item.title}
                content={item.content}
                isOpen={openPanel === index}
                onClick={() => handlePanelClick(index)}
              />
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default FooterAccordion;
