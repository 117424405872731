import HomeSlider from "../components/HomeSlider";
import WhyChooseUs from "../components/WhyChooseUs";
import ArtificialIntelligence from "../components/ArtificialIntelligence";
import LoansWeProvide from "../components/LoansWeProvide";

function Home() {
  return (
    <>
      <HomeSlider />
      <WhyChooseUs />
      <ArtificialIntelligence />
      <LoansWeProvide />
    </>
  );
}

export default Home;
