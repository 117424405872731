import Container from "../components/Container";

const AdBanner = ({ dir = "left", img, alt, children }) => {
  const containerClass = dir === "left" ? "order-1" : "order-2";

  return (
    <section
      className={`py-8 text-white ${
        dir === "left"
          ? `bg-[url('./assets/inner-banners/ashapurti_adBanner-BG1.jpg')] bg-cover bg-no-repeat`
          : `bg-[url('./assets/inner-banners/ashapurti_adBanner-BG2.jpg')] bg-cover bg-no-repeat`
      }`}
    >
      <Container className="grid gtid-cols-1 md:grid-cols-2 gap-12">
        <div className={containerClass}>
          <img
            src={img}
            alt={alt}
            width={700}
            className="rounded-lg object-cover"
          />
        </div>
        <div
          className={
            dir === "left"
              ? "order-2 flex flex-col justify-center"
              : "order-1 flex flex-col justify-center"
          }
        >
          {children}
        </div>
      </Container>
    </section>
  );
};
export default AdBanner;
