import { useState } from "react";
import Container from "./Container";
import IMGBLACK1 from "../assets/loans/icons/blackIcons/Icons-01.svg";
import IMGBLACK2 from "../assets/loans/icons/blackIcons/Icons-02.svg";
import IMGBLACK3 from "../assets/loans/icons/blackIcons/Icons-03.svg";
import IMGBLACK4 from "../assets/loans/icons/blackIcons/Icons-04.svg";
import IMGBLACK5 from "../assets/loans/icons/blackIcons/Icons-05.svg";
import IMGBLACK6 from "../assets/loans/icons/blackIcons/Icons-06.svg";
import IMGBLACK7 from "../assets/loans/icons/blackIcons/Icons-07.svg";
import IMGBLACK8 from "../assets/loans/icons/blackIcons/Icons-08.svg";
import IMGBLACK9 from "../assets/loans/icons/blackIcons/Icons-09.svg";
import IMGBLACK11 from "../assets/loans/icons/blackIcons/Icons-11.svg";
import IMGBLACK12 from "../assets/loans/icons/blackIcons/Icons-12.svg";
import IMGBLACK14 from "../assets/loans/icons/blackIcons/Icons-14.svg";
import IMGBLACK15 from "../assets/loans/icons/blackIcons/Icons-15.svg";

import IMGWHITE1 from "../assets/loans/icons/whiteIcons/Yellow_Icons-62.svg";
import IMGWHITE2 from "../assets/loans/icons/whiteIcons/Yellow_Icons-63.svg";
import IMGWHITE3 from "../assets/loans/icons/whiteIcons/Yellow_Icons-64.svg";
import IMGWHITE4 from "../assets/loans/icons/whiteIcons/Yellow_Icons-65.svg";
import IMGWHITE5 from "../assets/loans/icons/whiteIcons/Yellow_Icons-66.svg";
import IMGWHITE6 from "../assets/loans/icons/whiteIcons/Yellow_Icons-67.svg";
import IMGWHITE7 from "../assets/loans/icons/whiteIcons/Yellow_Icons-68.svg";
import IMGWHITE8 from "../assets/loans/icons/whiteIcons/Yellow_Icons-69.svg";
import IMGWHITE9 from "../assets/loans/icons/whiteIcons/Yellow_Icons-70.svg";
import IMGWHITE11 from "../assets/loans/icons/whiteIcons/Yellow_Icons-72.svg";
import IMGWHITE12 from "../assets/loans/icons/whiteIcons/Yellow_Icons-73.svg";
import IMGWHITE14 from "../assets/loans/icons/whiteIcons/Yellow_Icons-75.svg";
import IMGWHITE15 from "../assets/loans/icons/whiteIcons/Yellow_Icons-76.svg";

const data = [
  {
    id: 1,
    slug: "gold-loan",
    name: "Gold",
    x: 0,
    imgBlack: IMGBLACK1,
    imgWhite: IMGWHITE1,
  },
  {
    id: 2,
    slug: "vehicle-loan",
    name: "Vehicle",
    x: 0,
    imgBlack: IMGBLACK2,
    imgWhite: IMGWHITE2,
  },
  {
    id: 3,
    slug: "property-loan",
    name: "Property",
    x: 0,
    imgBlack: IMGBLACK3,
    imgWhite: IMGWHITE3,
  },
  {
    id: 4,
    slug: "shares-security-loan",
    name: "Shares & Security",
    x: 0,
    imgBlack: IMGBLACK4,
    imgWhite: IMGWHITE4,
  },
  {
    id: 5,
    slug: "home-loan",
    name: "Home",
    x: 0,
    imgBlack: IMGBLACK5,
    imgWhite: IMGWHITE5,
  },
  {
    id: 6,
    slug: "business-loan",
    name: "Business",
    x: 0,
    imgBlack: IMGBLACK6,
    imgWhite: IMGWHITE6,
  },
  {
    id: 7,
    slug: "personal-loan",
    name: "Personal",
    x: 0,
    imgBlack: IMGBLACK7,
    imgWhite: IMGWHITE7,
  },
  {
    id: 8,
    slug: "wedding-loan",
    name: "Wedding",
    x: 0,
    imgBlack: IMGBLACK8,
    imgWhite: IMGWHITE8,
  },
  {
    id: 9,
    slug: "/professional-loans/doctor-loan",
    name: "Doctor",
    x: 0,
    imgBlack: IMGBLACK9,
    imgWhite: IMGWHITE9,
  },
  // {
  //   id: 10,
  //   name: "Travel",
  //   x: 0,
  //   imgBlack: IMGBLACK10,
  //   imgWhite: IMGWHITE10,
  // },
  {
    id: 10,
    slug: "holiday-loan",
    name: "Holiday",
    x: 0,
    imgBlack: IMGBLACK11,
    imgWhite: IMGWHITE11,
  },
  {
    id: 11,
    slug: "/professional-loans/ca-loan",
    name: "CA",
    x: 0,
    imgBlack: IMGBLACK12,
    imgWhite: IMGWHITE12,
  },
  {
    id: 12,
    slug: "sme-loan",
    name: "SME",
    x: 0,
    imgBlack: IMGBLACK14,
    imgWhite: IMGWHITE14,
  },
  {
    id: 13,
    slug: "arts-antique-loan",
    name: "Arts & Antiques",
    x: 0,
    imgBlack: IMGBLACK15,
    imgWhite: IMGWHITE15,
  },
];
const LoansWeProvide = () => {
  const [hoverIndex, setHoveredIndex] = useState(null);
  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = () => {
    setFlippedIndex(flippedIndex === hoverIndex ? null : hoverIndex);
  };

  return (
    <>
      <section className="py-12 bg-slate-100">
        <Container className={""}>
          <h4 className="text-3xl lg:text-5xl text-center mb-6">
            <span className="text-green-600 font-bold">Loans</span> We Provide
          </h4>
          <div className="flex items-center justify-center flex-wrap gap-6 md:gap-x-8 md:gap-y-10">
            {data.map((item, index) => {
              return (
                <a
                  href={`/${item.slug}`}
                  key={index}
                  className="text-center flex flex-col items-center group flipBox"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="relative mb-2 md:w-[150px] md:h-[150px] flipBox_inner">
                    <div
                      className={`flipBox_front bg-white md:w-[150px] md:h-[150px] border-2 border-green-600 rounded-3xl flex items-center justify-center shadow-lg duration-700 ease-in-out ${
                        flippedIndex === index ? "flipped" : ""
                      }
                      }`}
                    >
                      <img
                        src={item.imgBlack}
                        alt={""}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div
                      className={`flipBox_back bg-green-900 md:w-[150px] md:h-[150px border-2 border-white rounded-3xl flex flex-col items-center justify-center shadow-lg duration-700 ease-in-out ${
                        flippedIndex === index ? "flipped" : ""
                      }`}
                      style={{ backgroundPositionX: index * -100 }}
                    >
                      <img
                        src={item.imgWhite}
                        alt={""}
                        width={100}
                        height={100}
                      />
                    </div>
                  </div>
                  <h5 className="text-sm md:text-md xl:text-xl font-medium text-slate-900">
                    {item.name}
                  </h5>
                </a>
              );
            })}
          </div>
        </Container>
      </section>
    </>
  );
};
export default LoansWeProvide;
